const defaultLocale = 'en';

const CURRENCY_SHORTS = {
  EUR: '€',
  GBP: '£',
  SYP: '£',
  SHP: '£',
  LBP: '£',
  JEP: '£',
  IMP: '£',
  GGP: '£',
  GIP: '£',
  FKP: '£',
  EGP: '£',
  JPY: '¥',
  CNY: '¥',
  RUB: '₽',
  KPW: '₩',
  KRW: '₩',
  CHF: 'CHF',
};

export function getLocale(languageCode = defaultLocale) {
  return ['development', 'test'].includes(process.env.NODE_ENV)
    ? defaultLocale
    : languageCode.substring(0, 2);
}

export const localeFormattedPrice = (
  amount,
  { languageCode = 'en', currencyCode, ...otherOptions } = {}
) => {
  const numericValue = parseFloat(amount);

  const formatPrice = (value) => {
    if (!currencyCode) {
      console.error('Invalid currency code');
      return amount;
    }
    const formattedPrice = value.toLocaleString(languageCode, {
      style: 'currency',
      currency: currencyCode,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      ...otherOptions,
    });
    return formattedPrice;
  };
  if (isNaN(numericValue)) {
    return formatPrice(0);
  }

  return formatPrice(numericValue);
};

export const getCurrencyShort = (currency) => {
  // Fallback covers USD; AUD, SGD and many more
  return CURRENCY_SHORTS[currency] || '$';
};

export function loadResource(tagName, parentNode, properties) {
  return new Promise((resolve, reject) => {
    const script = document.createElement(tagName);
    if (properties && typeof properties === 'object') {
      Object.entries(properties).forEach(([key, value]) => {
        script[key] = value;
      });
    }
    script.addEventListener('load', resolve);
    script.addEventListener('error', (e) => reject(e.error));
    parentNode.appendChild(script);
  });
}

export function loadScript(src, properties) {
  return loadResource('script', document.body, { src, ...properties });
}

export function loadLink(href, properties) {
  return loadResource('link', document.head, { href, ...properties });
}

export function loadStripeSDK() {
  return loadScript('https://js.stripe.com/v3/');
}

export function loadPayPalSDK({ clientId, currency, intent = 'capture' }) {
  return loadScript(
    `https://www.paypal.com/sdk/js?client-id=${clientId}&currency=${currency}&intent=${intent}`
  );
}
